<template>

    <div>
        <b-card no-body>
            <b-card-header>
                <b-card-title>Контракты
                    <feather-icon
                        icon="FilterIcon"
                        size="16"
                        style="cursor:pointer;"
                        :class="!isFilter ? 'text-muted' : ''"
                        @click="isFilter = !isFilter"
                    />
                </b-card-title>
                <div class="d-flex align-items-center">
                    <b-form-input  type="text" placeholder="Поиск по номеру" @change="get(true)" v-model="search" size="sm" style="border-radius: 100px; min-width: 200px;" class="mr-1"  />
                    <b-button :to="{ name: 'admin-contracts-offers' }" pill variant="light" style="line-height: 1; min-width: 150px;">
                        Предложения
                    </b-button>
                    <b-button @click="isOnCreateContractState = true" pill variant="primary" class="ml-1" style="line-height: 1; min-width: 200px;">
                        Создать контракт
                    </b-button>
                </div>
                
            </b-card-header>

            <hr v-if="isFilter" class="mt-0 mb-0">

            <transition name="fade">
                <b-card-header v-if="isFilter">
                    <b-card-title>
                        <span style="font-size: 14px">Фильтры</span>

                        <b-button @click="cleanFilter" pill variant="light" class="ml-1" style="line-height: 1;">
                            Очистить
                        </b-button>
                    </b-card-title>
                    <div class="d-flex align-items-center">
                        <!-- <div class="d-flex w-100 justify-content-between" style="align-items: center;"> -->
                            <span class="mb-0 mr-1">
                                Оплачены
                            </span>
                            <div class="d-flex ml-2">
                                <b-form-checkbox
                                    class="ml-2"
                                    name="is-buy"
                                    v-model="filter.isPaid"
                                    @change="get(true)"
                                    switch
                                    style="right: 44px;"
                                />
                            </div>
                        <!-- </div> -->
                        <b-form-select style="width: 200px; border-radius: 16px;" v-model="filter.status" :options="statuses" size="sm" class="ml-2"></b-form-select>
                        <b-form-select style="width: 200px; border-radius: 16px;" v-model="filter.type" :options="types" size="sm" class="ml-2"></b-form-select>

                    </div>
                </b-card-header>
            </transition>

            
            <b-skeleton-table v-if='is_loading' animation="fade" :columns='10' :rows='5'>
            
            </b-skeleton-table>
            
            <b-table-simple v-else responsive >
                <b-thead>
                    <b-tr class="font-small-2 text-nowrap text-muted">
                        <b-td>Номер</b-td>
                        <b-td>Клиент</b-td>
                        <b-td>Хэшрейт
                            <feather-icon
                                v-if="orderBy.column === 'ths' && orderBy.sortBy == 'desc'"
                                icon="ChevronDownIcon"
                                size="16"
                                @click="updateOrderBy('ths')"
                                style="cursor:pointer"
                            />
                            <feather-icon
                                v-else-if="orderBy.column === 'ths' && orderBy.sortBy == 'asc'"
                                icon="ChevronUpIcon"
                                size="16"
                                @click="updateOrderBy('ths')"
                                style="cursor:pointer"
                            />
                            <feather-icon
                                v-else
                                icon="CodeIcon"
                                size="12"
                                @click="updateOrderBy('ths')"
                                style="cursor:pointer; transform: rotate(90deg);"
                            />
                        </b-td>
                        <b-td>Период</b-td>
                        <b-td>Тип</b-td>
                        <b-td>Майнеры</b-td>

                        <b-td>Добыто
                            <feather-icon
                                v-if="orderBy.column === 'total_balance' && orderBy.sortBy == 'desc'"
                                icon="ChevronDownIcon"
                                size="16"
                                @click="updateOrderBy('total_balance')"
                                style="cursor:pointer"
                            />
                            <feather-icon
                                v-else-if="orderBy.column === 'total_balance' && orderBy.sortBy == 'asc'"
                                icon="ChevronUpIcon"
                                size="16"
                                @click="updateOrderBy('total_balance')"
                                style="cursor:pointer"
                            />
                            <feather-icon
                                v-else
                                icon="CodeIcon"
                                size="12"
                                @click="updateOrderBy('total_balance')"
                                style="cursor:pointer; transform: rotate(90deg);"
                            />
                        </b-td>
                        <b-td>С/С</b-td>
                        <b-td>Дата</b-td>
                        <b-td>Статус</b-td>
                    </b-tr>
                </b-thead>
                <b-tbody>
                    <b-tr v-for="item in items" :key="item.guuid" :class="{
                        'font-small-3' : true,
                        'bg-light-danger' : item.status === 5
                    }">
                        <b-td class='text-black font-weight-bolder font-small-3'>
                            <b-link :to="{ name: 'admin-contracts-view', params: { id: item.guuid } }">{{ item.guuid }}</b-link>
                        </b-td>
                        <b-td class='text-black font-weight-bolder font-small-3'>
                            <b-link :to="{ name: 'admin-users-view', params: { id: item.creator.id} }">
                                <b-avatar
                                    :variant="Users.utils.resolveAvatarColor( item.creator.id )"
                                    :text="Users.utils.capitalize(`${item.creator.firstName}`, 1 )"
                                    style="width: 25px; height: 25px;"
                                />
                            </b-link>
                        </b-td>
                        <b-td class='text-black font-weight-normal text-nowrap font-small-3'>
                            {{ item.ths.formatMoney(2," "," ") }}
                            <span class="text-muted">TH/s</span>
                        </b-td>
                        <b-td class='text-black font-weight-normal text-nowrap font-small-3'>
                            <span v-if="item.period">
                                {{ item.period }}
                                 <span class="text-muted">мес.</span>
                            </span>
                            <span v-else class="text-muted">
                                –
                            </span>
                        </b-td>
                        <b-td class='text-black font-weight-normal text-nowrap'>
                            <template v-if="item.type === 1">
                                <feather-icon icon="CloudIcon" size="13" class="text-primary" />
                                <span class="font-small-3" style="padding-left: 5px;">Облако</span>
                            </template>
                            <template v-if="item.type === 2">
                                <feather-icon icon="CpuIcon" size="13" class="text-danger" />
                                <span class="font-small-3" style="padding-left: 5px;">Майнер</span>
                            </template>
                        </b-td>
                        <b-td class='text-black font-weight-normal'>
                            <span v-if="item.type === 2">
                                {{ item.miners_count }}
                            </span>
                            <span v-else class="text-muted">
                                –
                            </span>
                        </b-td>

                        <b-td class='text-black font-weight-normal text-nowrap'>
                            {{ toUSD(item.current_balance).formatMoney(2," ", " ") }}
                            <span class="text-muted">$</span>
                        </b-td>
                        <b-td class='text-black font-weight-normal text-nowrap'>
                            {{ toUSD(item.total_balance - item.current_balance).formatMoney(2," ", " ") }}
                            <span class="text-muted">$</span>
                        </b-td>
                        <b-td class='text-black font-weight-normal text-nowrap'>
                            {{ item.date }} {{ localTime(item.created_at) }}
                        </b-td>
                        <b-td class='text-black font-weight-normal font-small-3'>
                            <div v-if="item.status === 1">
                                <b-badge variant="success">Работает</b-badge>
                            </div>
                            <div v-else-if="item.status === 2">
                                <b-badge variant="primary" class='badge-start-view'>Оплата получена</b-badge>
                            </div>
                            <div v-else-if="item.status === 0">
                                <b-badge v-if="item.invoice && (item.invoice.received > 0 || item.invoice.unconfirmed > 0) " style="text-align:left" variant="danger"> Частично оплачено <br>получено: {{item.invoice.received + item.invoice.unconfirmed}} <br> осталось: {{item.invoice.btc - (item.invoice.received + item.invoice.unconfirmed)}}</b-badge>
                                 <b-badge v-else-if="!item.invoice" style="text-align:left" variant="danger"> Ошибка создания контракта! (Пересоздать) </b-badge>
                                <b-badge v-else variant="danger">Требуется оплата</b-badge>
                            </div>
                            <div v-else-if="item.status === 3">
                                <b-badge variant="primary" class='badge-start-view'>
                                    Старт {{ Utils.daysAgo( item.start_at ) }}
                                </b-badge>
                            </div>
                            <div v-else-if="item.status === 4">
                                <b-badge variant="secondary">Завершен</b-badge>
                            </div>
                            <div v-else-if="item.status === 5">
                                 <b-badge variant="danger">Не оплачен</b-badge>
                            </div>
                            <div v-else-if="item.status === 6">
                                 <b-badge variant="secondary">Архивный</b-badge>
                            </div>
                            <div v-else-if="item.status === 7">
                                 <b-badge variant="secondary">Приостановлен</b-badge>
                            </div>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple> 
            <b-card-body v-if="!is_loading" class="d-flex justify-content-between pt-0 pb-0">
                <span class="font-weight-bolder">Всего: {{ total_count }}</span>

                <b-pagination-nav 
                    :value="current_page" 
                    :link-gen="linkGen" 
                    :number-of-pages="last_page" 
                    use-router 
                ></b-pagination-nav>
            </b-card-body>
        </b-card>
        
         <ContractCreateModal  
            :show="isOnCreateContractState"
            @closed="isOnCreateContractState = false"
        />
        
    </div>
    
</template>

<script>

    import Utils from "@/modules/utils/"
    import Users from "@/modules/admin/users/"
    import moment from 'moment'

    import ContractCreateModal from "./modals/ContractCreateModal"


    export default {

        data() {
            return {
                Utils,
                Users,
                items: [],
                current_page: 0,
                has_more_pages: false,
                last_page: 0,
                total_count: 0,
                isOnCreateContractState: false,
                is_loading: true,
                search: '',
                isFilter: false,
                filter: {
                    isPaid: false,
                    status: null,
                    type: null
                },
                statuses: [
                    { value: null, text: 'Статус' },
                    { value: 0, text: 'Требуется оплата' },
                    { value: 1, text: 'Работает' },
                    { value: 3, text: 'Доставка' },
                    { value: 4, text: 'Завершен' },
                    { value: 5, text: 'Не оплачен' },
                    { value: 6, text: 'Архив' },
                    { value: 7, text: 'Приостановлен' },
                    { value: 8, text: 'Установка оборудования' },

                ],
                types: [
                    { value: null, text: 'Тип контракта' },
                    { value: 1, text: 'Облако' },
                    { value: 2, text: 'Майнер' },
                ],
                orderBy: {
                    sortBy: 'desc',
                    column: 'id'
                },
            }
        },
        methods: {

            cleanFilter() {
                this.filter =  {
                    isPaid: false,
                    status: null,
                    type: null
                };

                this.orderBy = {
                    sortBy: 'desc',
                    column: 'id'
                };

                this.get(true);
            },

            updateOrderBy(column) {
                
                if (this.orderBy.column == column) {
                    this.orderBy.sortBy = this.orderBy.sortBy == 'desc' ? 'asc' : 'desc'
                } else {
                    this.orderBy.sortBy = 'desc';
                    this.orderBy.column = column;
                }

                this.get(true);

            },
            
            localTime(created_at) {
                return moment.utc(created_at * 1000).local().format('HH:mm')
            },
            
            linkGen( pageNum ) {
                return {
                    name: this.$route.name,
                    query: {
                        page: pageNum
                    }
                }        
            },
            
            toUSD( value ) {
                return ( value * this.$blockchain.tickers.values['USD'].last );
            },
            
            get(clear = false) {
                
                this.is_loading = true;
                if (clear) this.$route.query.page = 1

                this.$request.get("contracts/list", {
                    page: this.$route.query.page,
                    search: this.search,
                    orderby: this.orderBy,
                    filter: this.filter
                } ).then(rsp => {
                   this.is_loading = false;
                   this.items = rsp.items;
                   this.total_count = rsp.total_count;
                   this.current_page = rsp.current_page;
                   this.last_page = rsp.last_page;
                });
            },
        },
         computed: {
            filter() {
                return this.items.filter(post => {
                    return (
                        (post.guuid !== null && post.guuid.toLowerCase().includes(this.search.toLowerCase())) 
                    )
                })
            },
        },
        components: {
            ContractCreateModal
        },
        watch: {
            "$route.query" : function () {
                this.get();
            },
            "filter": {
                deep: true,
                handler(bef) {
                    this.get(true)
                }
            }
        },
        mounted() {
            this.get();
        }

    }

</script>