var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"title":"Создать контракт","centered":"","hide-footer":""},on:{"hide":function($event){return _vm.$emit('closed')}},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('validation-observer',{ref:"form"},[_c('b-form',[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"user","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.users,"label":"email","clearable":false,"placeholder":"Выбрать пользователя"},on:{"search":_vm.fetchUsers},model:{value:(_vm.form.user),callback:function ($$v) {_vm.$set(_vm.form, "user", $$v)},expression:"form.user"}},[_c('template',{slot:"no-options"},[_vm._v(" Нет результатов поиcка ")])],2),(errors)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.types,"label":"name","clearable":false,"placeholder":"Выбрать тип контракта"},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}}),(errors)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.clauses,"label":"name","clearable":false,"placeholder":"Выбрать статью создания"},model:{value:(_vm.form.clause),callback:function ($$v) {_vm.$set(_vm.form, "clause", $$v)},expression:"form.clause"}}),(errors)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1),(_vm.form.clause && _vm.form.clause.value === 100)?_c('b-form-group',[_c('validation-provider',{attrs:{"name":"type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',{staticClass:"text-muted",staticStyle:{"font-size":"12px"}},[_vm._v("Статья создания контракта")]),_c('b-form-textarea',{attrs:{"placeholder":"Укажите причину создания контракта"},model:{value:(_vm.form.clause_other),callback:function ($$v) {_vm.$set(_vm.form, "clause_other", $$v)},expression:"form.clause_other"}}),(errors)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,false,1845128213)})],1):_vm._e(),(_vm.form.type && _vm.form.type.value === 1)?[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"investments","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',{staticClass:"text-muted",staticStyle:{"font-size":"12px"}},[_vm._v("Сумма вложений (необязательно) "),_c('feather-icon',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Если сумма не указана, то вложения за контракт не будут учитываться при разблокировки партнерской программы'),expression:"'Если сумма не указана, то вложения за контракт не будут учитываться при разблокировки партнерской программы'",modifiers:{"hover":true,"top":true}}],staticClass:"cursor-pointer info-hover",staticStyle:{"margin-left":"0px"},attrs:{"icon":"AlertCircleIcon"}})],1),_c('cleave',{staticClass:"form-control",attrs:{"raw":false,"options":_vm.investments,"placeholder":"Сумма в $"},model:{value:(_vm.form.investments),callback:function ($$v) {_vm.$set(_vm.form, "investments", $$v)},expression:"form.investments"}}),(errors)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,false,2782907385)})],1),_c('b-form-group',{staticClass:"form-group-with-label"},[_c('div',{staticClass:"d-flex justify-content-between",staticStyle:{"margin-bottom":"4px"}},[_c('div',{staticStyle:{"flex-grow":"1"}},[_c('validation-provider',{attrs:{"name":"power","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(errors[0])?_c('div',{staticClass:"text-danger"},[_c('span',{staticStyle:{"font-size":"12px","font-weight":"500"}},[_vm._v(" Мощность контракта ")]),_c('feather-icon',{attrs:{"icon":"AlertCircleIcon"}})],1):_c('span',{staticClass:"text-muted",staticStyle:{"font-size":"12px"}},[_vm._v("Мощность контракта")]),_c('b-form-input',{attrs:{"type":"number","placeholder":"Мощность контракта"},model:{value:(_vm.form.ths),callback:function ($$v) {_vm.$set(_vm.form, "ths", _vm._n($$v))},expression:"form.ths"}})]}}],null,false,3604118078)})],1),_c('div',{staticClass:"mt-auto mb-auto"},[_c('span',{staticClass:"text-muted"},[_vm._v("TH/s")])])]),_c('vue-slider',{attrs:{"min":1,"max":500,"dotSize":16,"height":6,"contained":false,"interval":1,"duration":0.2,"tooltip":"none"},model:{value:(_vm.form.ths),callback:function ($$v) {_vm.$set(_vm.form, "ths", $$v)},expression:"form.ths"}})],1),_c('div',{staticClass:"d-flex justify-content-between mb-1"},[_c('span',{staticClass:"text-muted",staticStyle:{"font-size":"12px"}},[_vm._v("1 TH/s")]),_c('span',{staticClass:"text-muted",staticStyle:{"font-size":"12px"}},[_vm._v("500 TH/s")])]),_c('b-form-group',{staticClass:"form-group-with-label"},[_c('div',{staticClass:"d-flex justify-content-between",staticStyle:{"margin-bottom":"4px"}},[_c('div',{staticStyle:{"flex-grow":"1"}},[_c('validation-provider',{attrs:{"name":"period","rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(errors[0])?_c('div',{staticClass:"text-danger"},[_c('span',{staticStyle:{"font-size":"12px","font-weight":"500"}},[_vm._v(" Срок действия контракта ")]),_c('feather-icon',{attrs:{"icon":"AlertCircleIcon"}})],1):_c('span',{staticClass:"text-muted",staticStyle:{"font-size":"12px"}},[_vm._v("Срок действия контракта")]),_c('b-form-input',{attrs:{"type":"number","placeholder":"Срок действия контракта","min":12,"max":24,"formatter":_vm.periodFormatter},model:{value:(_vm.form.period),callback:function ($$v) {_vm.$set(_vm.form, "period", _vm._n($$v))},expression:"form.period"}})]}}],null,false,2744117280)})],1),_c('div',{staticClass:"mt-auto mb-auto"},[_c('span',{staticClass:"text-muted"},[_vm._v("мес.")])])]),_c('vue-slider',{attrs:{"min":12,"max":36,"dotSize":16,"height":6,"contained":false,"interval":1,"duration":0.2,"tooltip":"none"},model:{value:(_vm.form.period),callback:function ($$v) {_vm.$set(_vm.form, "period", $$v)},expression:"form.period"}})],1),_c('div',{staticClass:"d-flex justify-content-between mb-1"},[_c('span',{staticClass:"text-muted",staticStyle:{"font-size":"12px"}},[_vm._v("12 месяцев")]),_c('span',{staticClass:"text-muted",staticStyle:{"font-size":"12px"}},[_vm._v("36 месяцев")])])]:_vm._e(),(_vm.form.type && _vm.form.type.value === 2)?[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"miners","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.miners,"label":"name","multiple":"","placeholder":"Выбрать майнеры"},on:{"search":_vm.fetchMiners},model:{value:(_vm.form.miners),callback:function ($$v) {_vm.$set(_vm.form, "miners", $$v)},expression:"form.miners"}},[_c('template',{slot:"no-options"},[_vm._v(" Нет результатов поиcка ")])],2),(errors)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,false,969394104)})],1)]:_vm._e(),_c('div',{staticClass:"d-flex justify-content-end"},[_c('b-button',{attrs:{"pill":"","variant":"primary"},on:{"click":_vm.submit}},[_vm._v(" Создать ")])],1)],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }