<template>
    <b-modal 
        title="Создать контракт" 
        v-model="show" 
        centered 
        hide-footer
        @hide="$emit('closed')"
    >
        
        <validation-observer ref="form">
            <b-form>
                
                
                
                <b-form-group >
                    <validation-provider #default="{ errors }" name="user" rules="required" >
                        <v-select
                          :options="users"
                          v-model="form.user"
                          label="email"
                          :clearable="false"
                          @search="fetchUsers"
                          placeholder="Выбрать пользователя"
                        >
                            <template slot="no-options">
                                Нет результатов поиcка
                            </template>
                        </v-select>         
                        <small v-if="errors" class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    
                </b-form-group> 
                
                <b-form-group >
                    <validation-provider #default="{ errors }" name="type" rules="required" >
                        <v-select
                          :options="types"
                          v-model="form.type"
                          label="name"
                          :clearable="false"
                          placeholder="Выбрать тип контракта"
                        />              
                        <small v-if="errors" class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group> 

                <b-form-group >
                    <validation-provider #default="{ errors }" name="type" rules="required" >
                        <v-select
                          :options="clauses"
                          v-model="form.clause"
                          label="name"
                          :clearable="false"
                          placeholder="Выбрать статью создания"
                        />              
                        <small v-if="errors" class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>  

                <b-form-group v-if="form.clause && form.clause.value === 100">
                    <validation-provider #default="{ errors }" name="type" rules="required" >
                       <span class="text-muted" style="font-size: 12px;">Статья создания контракта</span>
                           
                            <b-form-textarea  placeholder="Укажите причину создания контракта" v-model="form.clause_other" />
                            <small v-if="errors" class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>   
                
                
                <template v-if="form.type && form.type.value === 1">

                    <b-form-group >
                        <validation-provider #default="{ errors }" name="investments" rules="" >
                            <span class="text-muted" style="font-size: 12px;">Сумма вложений (необязательно)
                                <feather-icon 
                                    v-b-popover.hover.top="'Если сумма не указана, то вложения за контракт не будут учитываться при разблокировки партнерской программы'"
                                    icon="AlertCircleIcon" 
                                    class="cursor-pointer info-hover" 
                                    style="margin-left: 0px;"
                                />

                            </span>
                           
                            <cleave
                                v-model="form.investments"
                                class="form-control"
                                :raw="false"
                                :options="investments"
                                placeholder="Сумма в $"
                            />          
                            <small v-if="errors" class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>   
                    
                    <b-form-group class="form-group-with-label">
                        
                        <div class="d-flex justify-content-between" style="margin-bottom: 4px;">
                            <div style="flex-grow: 1;">
                                <validation-provider
                                    #default="{ errors }"
                                    name="power"
                                    rules="required"
                                >
                                    <div v-if='errors[0]' class='text-danger'>
                                        <span  style="font-size: 12px; font-weight: 500;">
                                            Мощность контракта
                                        </span>
                                        <feather-icon icon='AlertCircleIcon' />
                                    </div>
                                    <span v-else class='text-muted' style="font-size: 12px;">Мощность контракта</span>
                                    <b-form-input
                                        type="number"
                                        placeholder="Мощность контракта"
                                        v-model.number="form.ths"
                                    />
                                </validation-provider>
                            </div>
                            <div class="mt-auto mb-auto">
                                <span class="text-muted">TH/s</span>
                            </div>
                        </div>
                            
                        <vue-slider
                            v-model="form.ths"
                            :min="1"
                            :max="500"
                            :dotSize="16"
                            :height="6"
                            :contained="false"
                            :interval="1"
                            :duration="0.2"
                            tooltip="none"
                        />
                    </b-form-group>
                    
                    <div class="d-flex justify-content-between mb-1">
                        <span class="text-muted" style="font-size: 12px;">1 TH/s</span>
                        <span class="text-muted" style="font-size: 12px;">500 TH/s</span>
                    </div>
                    
                    <b-form-group class="form-group-with-label">
                        
                        <div class="d-flex justify-content-between" style="margin-bottom: 4px;">
                            <div style="flex-grow: 1;">
                                <validation-provider
                                    #default="{ errors }"
                                    name="period"
                                    rules="required|integer"
                                >
                                    <div v-if='errors[0]' class='text-danger'>
                                        <span  style="font-size: 12px; font-weight: 500;">
                                            Срок действия контракта
                                        </span>
                                        <feather-icon icon='AlertCircleIcon' />
                                    </div>
                                    <span v-else class='text-muted' style="font-size: 12px;">Срок действия контракта</span>
                                    <b-form-input
                                        type="number"
                                        placeholder="Срок действия контракта"
                                        v-model.number="form.period"
                                        :min="12"
                                        :max="24"
                                        :formatter="periodFormatter"

                                    />
                                </validation-provider>
                            </div>
                            <div class="mt-auto mb-auto">
                                <span class="text-muted">мес.</span>
                            </div>
                        </div>
                            
                        <vue-slider
                            v-model="form.period"
                            :min="12"
                            :max="36"
                            :dotSize="16"
                            :height="6"
                            :contained="false"
                            :interval="1"
                            :duration="0.2"
                            tooltip="none"
                        />
                    </b-form-group>
                    
                    <div class="d-flex justify-content-between mb-1">
                        <span class="text-muted" style="font-size: 12px;">12 месяцев</span>
                        <span class="text-muted" style="font-size: 12px;">36 месяцев</span>
                    </div>
                    
                </template>
                <template v-if="form.type && form.type.value === 2">
                    
                    <b-form-group >
                        <validation-provider #default="{ errors }" name="miners" rules="required" >
                            <v-select
                              :options="miners"
                              v-model="form.miners"
                              label="name"
                              @search="fetchMiners"
                              multiple
                              placeholder="Выбрать майнеры"
                            >
                                <template slot="no-options">
                                    Нет результатов поиcка
                                </template>
                            </v-select>      
                            <small v-if="errors" class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group> 
                </template>
                
                <div class="d-flex justify-content-end">
                    <b-button @click="submit" pill variant="primary">
                        Создать
                    </b-button>
                </div>
                
            </b-form>    
        </validation-observer>
        
    </b-modal>    
</template>

<script>

    import vSelect from 'vue-select'
    import VueSlider from 'vue-slider-component'
    import Cleave from 'vue-cleave-component'
     
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required, integer } from '@validations'
    
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

    export default {

        data() {
            return {
                
                required,
                integer,
                
                users: [],
                miners: [],
                
                form: {
                    user: null,
                    type: null,
                    miners: [],
                    ths: 1,
                    period: 12,
                    investments: 0,
                    clause: null,
                    clause_other: ''
                },
                
                types: [
                    { name: "Облако", value: 1 },
                    { name: "Майнеры", value: 2 },
                ],

                investments: {
                    numericOnly: true,
                    blocks:[6],
                    numeralThousandsGroupStyle: 'none'
                },

                clauses: [
                    { name: "Оплата вне сервиса", value: 1 },
                    { name: "Бартер", value: 2 },
                    { name: "В счет расчетного кошелька", value: 3 },
                    { name: "Иное", value: 100 },
                ],
                
            }
        },
        props: {
            show: Boolean
        },
        methods: {
            
            
            submit( args ) {
                
                this.$refs.form.validate().then( success => {
                    if( success ) {

                        if( this.form.type.value === 1 ) {
                            this.$request.post("contracts/create", {
                                user: this.form.user.id,
                                type: this.form.type.value,
                                ths: this.form.ths,
                                period: this.form.period,
                                investments: this.form.investments,
                                clause: this.form.clause.value,
                                clause_other: this.form.clause_other
                            }).then( rsp => {
                                this.$router.push({ name: 'admin-contracts-view', params: { id: rsp.id } })
                                this.show = false;
                            });
                        } else {
                            let miners = this.form.miners.map(item => item.id );
                            this.$request.post("contracts/create", {
                                user: this.form.user.id,
                                type: this.form.type.value,
                                miners: miners,
                                investments: this.form.investments,
                                clause: this.form.clause.value,
                                clause_other: this.form.clause_other
                            }).then( rsp => {
                                this.$router.push({ name: 'admin-contracts-view', params: { id: rsp.id } })
                                this.show = false;
                            });
                        }
                    }
                });
                
            },
            
            fetchUsers( query, is_loading ) {
                
                this.$request.get("users/search", {
                    q: query
                }).then( rsp => {
                    this.users = rsp;
                })
                
            },
            
            fetchMiners( query, loading ) {
                this.$request.get("miners/search", {
                    q: query
                }).then( rsp => {
                    this.miners = rsp.items;
                });
            },
            
            periodFormatter( args ) {
                
                let val = parseInt( args );
                
                if( val < 12 ) {
                    val = 12;
                } else if( val > 36 ) {
                    val = 36;
                } 
                
                return val;
                
            },
        },
        components: {
            vSelect,
            VueSlider,
            ValidationProvider,
            ValidationObserver,
            Cleave
        },
        watch: {

        },
        mounted() {

        }

    }

</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
    @import '@core/scss/vue/libs/vue-slider.scss';
</style>